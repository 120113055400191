import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import * as Styled from './slpHeaderWithTextStyle';
import { BLOCKS } from '@contentful/rich-text-types';

export default function SlpHeaderWithText({ sectionData }) {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.HeadContainer>
      <Styled.Head>
        <Styled.HeadLogo
          src={sectionData?.image?.file?.url}
          alt={sectionData?.image?.file?.fileName}
          title={sectionData?.image?.file?.fileName}
        />
        {sectionData?.richText && (
          <>
            {documentToReactComponents(
              JSON.parse(sectionData?.richText?.raw),
              optionsMainStyle
            )}
          </>
        )}
        <div style={{ clear: 'both' }}></div>
      </Styled.Head>
    </Styled.HeadContainer>
  );
}
